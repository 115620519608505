<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon
          large
          color="error"
        >
          mdi-alert
        </v-icon>
        OLT Down
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="oltDowns"
        disable-pagination
        hide-default-footer
        :loading="loading"
        loading-text="Loading..."
        class="elevation-1"
      >
        <template v-slot:item.downAt="{ item }">
          <span>
            {{ formatDate(item.downAt) }}
            <v-chip
              v-if="item.downAt"
              :color="getFromNowColor(item.downAt)"
              dark
            >
              {{ fromNow(item.downAt) }}
            </v-chip>
            <span
              v-else
            >
              <v-icon
                color="error"
              >
                mdi-alert
              </v-icon>
              ไม่พบข้อมูล โปรดตรวจสอบว่ายังใช้งานหรือไม่
              <v-icon
                color="error"
              >
                mdi-alert
              </v-icon>
            </span>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios'
import moment from 'moment'

moment.locale('th')

export default {
  name: 'OltDownList',
  props: {

  },
  data() {
    return {
      oltDowns: [],
      loading: false,
      headers: [
        {
          text: 'Sector',
          value: 'officeSector.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'OLT',
          value: 'name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'Project',
          value: 'oltProject.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'IP Address',
          value: 'ip',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Brand',
          value: 'oltType.oltBrand.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Type',
          value: 'oltType.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Time',
          value: 'downAt',
          sortable: true,
          align: 'center',
        },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI()
      },
      deep: true,
    },
  },
  created() {

  },
  mounted() {
    this.loaded = false
    this.getDataFromAPI()
  },
  methods: {
    formatDate(date) {
      if (date) {
        return moment(date).format('LLL')
      }
      return null
    },
    fromNow(date) {
      if (date) {
        return moment(date).fromNow()
      }
      return null
    },
    getFromNowColor(date) {
      if (date) {
        const now = moment(new Date())
        const duration = moment.duration(now.diff(date))
        const hourDiff = duration.asHours()
        if (hourDiff < 1) return 'yellow'
        if (hourDiff < 2) return 'amber'
        if (hourDiff < 3) return 'orange'
        if (hourDiff < 4) return 'deep-orange'
        return 'red'
      }
      return null
    },
    getDataFromAPI() {
      this.loading = true
      axios.get('/dashboard/oltDown')
        .then((response) => {
          // JSON responses are automatically parsed.
          this.oltDowns = response.data
          this.loading = false
        })
        .catch((e) => {
          this.errors.push(e)
          this.loading = false
        })
    },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
